import "lazysizes";
import "picturefill";

//アコーディオン
$(".js-menu__item__link").each(function () {
  $(this).on("click", function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

//ヘッダーメニュー
$("#header__spBtn").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");
    $("#header__spMenu").removeClass("is-active");
  } else {
    $(this).addClass("is-active");
    $("#header__spMenu").addClass("is-active");
  }
});

$(".header__spMenu__accordion").each(function () {
  $(this).on("click", function () {
    $("+.header__spMenu__accordion__list", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

// ページ内スクロール
$('a[href^="#"]').click(function () {
  console.log("test");
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  const position = target.offset().top - 80;
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

// アニメーション
const scrollFunc = function () {
  const target = document.getElementsByClassName("fade");
  const position = Math.floor(window.innerHeight * 0.9);
  for (let i = 0; i < target.length; i++) {
    let offsetTop = Math.floor(target[i]?.getBoundingClientRect().top);
    if (offsetTop < position) {
      target[i].classList.add("active");
    }
    // else {
    //   target[i].classList.remove("active");
    // }
  }
};
window.addEventListener("scroll", scrollFunc, false);

// プライバシー
$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<span></span><p><a class="wpcf7-list-item-label" href="${path}/privacy" target="_blank">` +
        $(this).text() +
        "</a>について同意する"
    );
  });
});
